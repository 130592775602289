<template>
  <div>
    <!-- Listagem dos Grupos -->
    <b-col style="width: 100%">
      <b-card>
        <div
          v-if="dados"
          class="col-lg-12 col-12"
        >
          <div class="col-lg-12 col-12">
            <div class="card card-company-table">
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table
                    id="example"
                    class="table"
                  >
                    <thead>
                      <tr>
                        <th>NOME</th>
                        <th>LINK DO GRUPO</th>
                        <th>DATA DE CADASTRO</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="dadoss in dados"
                      :key="dadoss.username"
                    >
                      <tr>
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="avatar rounded">
                              <div class="avatar-content">
                                <img
                                  src="@/assets/images/icons/star.svg"
                                  alt="Star svg"
                                >
                              </div>
                            </div>
                            <div>
                              <div class="font-weight-bolder">
                                {{ dadoss.name }}
                              </div>
                              <div class="font-small-2 text-muted">
                                {{ dadoss.username }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-items-center">
                            <a
                              target="_bank"
                              :href="`http://wa.me/${dadoss.phone}`"
                            >{{ dadoss.phone }}</a>
                          </div>
                        </td>
                        <td class="text-nowrap">
                          <div class="d-flex flex-column">
                            <span class="font-weight-bolder mb-25">
                              <span
                                class="font-weight-bolder mb-25"
                              >
                                <b-badge
                                  variant="success"
                                >
                                  {{ formatDate(dadoss.created_at) }}
                                </b-badge>
                              </span>
                            </span>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Modal View -->
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="StarIcon"
              />
              <span
                class="ml-25"
              >Voce nao possui nenhum cliente cadastrado.</span>
            </div>
          </b-alert>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :to="{ name: 'cadastrar-cliente' }"
          >
            Cadastrar Cliente
          </b-button>
        </div>
      </b-card>
    </b-col>
    <!-- Listagem dos Grupos -->
  </div>
</template>

<script>
import {
  BCard, BCol, BAlert, BBadge, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import funcs from '@/services/func.service'
import moment from 'moment'

export default {
  components: {
    BAlert,
    BCard,
    BBadge,
    BButton,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stickyHeader: true,
      dados: [],
    }
  },
  mounted() {
    this.listAllClients()
  },
  methods: {
    listAllClients() {
      funcs.listAllClients().then(success => {
        console.log(success)
        if (success.data) {
          this.dados = success.data
        }
      })
    },
    formatDate(date) {
      const newdate = `${moment(date)
        .utc()
        .format('DD/MM/YYYY')}`
      this.date_end = newdate
      return newdate
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
